import React from "react"
import { Card } from "react-bootstrap"
import Paragraph from "./Poem"

const paragraph1 =
  "A word is dead" + "\n" + "When it is said," + "\n" + "Some say." + "\n"

const paragraph2 =
  "I say it just" + "\n" + "Begins to live" + "\n" + "That day."

const homepage = () => {
  return (
    <Card style={{ border: "none", backgroundColor: "transparent" }}>
      <Card.Title style={{ marginBottom: "0.2rem" }}>1212</Card.Title>
      <Paragraph paragraph={paragraph1} />
      <Paragraph paragraph={paragraph2} />
      <Card.Text className="anti-copy">—Emily Dickinson</Card.Text>
      <Card.Text className="anti-copy">
        This is the first type of poetry I found as a child. It was my
        introduction to literature. I have been fortunate to work in the
        creative industries, share my passion for expression and utilise the
        arts as a platform for my own writing; create, produce, and engage with
        writing in many forms.
      </Card.Text>
      <Card.Text className="anti-copy">
        The website provides an overview and samples of projects and my creative
        work.
      </Card.Text>
    </Card>
  )
}

export default homepage
