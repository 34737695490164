import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import Image from "../components/Image"
import Layout from "../components/layout"
import Homepage from "../components/homepage"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Home - Pamela Mary Brown</title>
    </Helmet>
    <Container>
      <Row>
        <Col
          xs={{ order: "last", span: 12 }}
          sm={{ order: "last", span: 12 }}
          md={{ order: "first", span: 6 }}
          lg={{ order: "first", span: 6 }}
          xl={{ order: "first", span: 6 }}
        >
          <Homepage />
        </Col>

        <Col
          xs={{ order: "first", span: 12 }}
          sm={{ order: "first", span: 12 }}
          md={{ order: "last", span: 6 }}
          lg={{ order: "last", span: 6 }}
          xl={{ order: "last", span: 6 }}
        >
          <Image />
        </Col>
      </Row>
      <hr />
      <Row>
        {/* <div style={{ height: "2000px" }}>
          {" "} */}
        {/* This is the part Eoghan is creating{" "} */}
        {/* </div> */}
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
