import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const getImages = graphql`
  {
    fluid: file(relativePath: { eq: "pamPlaceholder.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const Images = () => {
  const data = useStaticQuery(getImages)
  return (
    <div>
      <Image fluid={data.fluid.childImageSharp.fluid} />
    </div>
  )
}

export default Images
